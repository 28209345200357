// Jwt storage jetexport const JWT_KEY = 'viewer_jwt';
export const VIEWER_TEAM_KEY = 'viewer_team_id';
// URL query params
export const QUERY_PARAM = {
  REDIRECT_PATH: 'r',
  AUTH_TOKEN: 'authToken',
  TEAM_ID: 'teamId',
};

export const SELECTED_VIDEO_DEVICE = 'askable_session.selected_video_device';

export const dataAttributeNames = {
  wordStart: 'data-wordstart',
  bar: 'data-bar',
  addNote: 'data-add-note',
  tagManagementPopover: 'data-tag-management-popover',
};

export const AttributeIds = {
  transcriptUtteranceScrollableContainer: 'transcript-utterance-scrollable-container',
  transcriptUtteranceContainer: 'transcript-utterance-container',
};

export const zIndexes = {
  insightCollectionMiddleHeaderMiddleContainer: 1,
  insightCollectionHeaderCustomizationDrawer: 2,
  transcriptContainer: 2,
  loadingSkeleton: 3,
  noteIconButton: 3,
  playbackTag: 3,
};

export const TAG_COLORS = [
  '#337FBD',
  '#2650BD',
  '#784FA7',
  '#6A1B9A',
  '#BF5396',
  '#C24E58',
  '#AD1457',
  '#B80000',
  '#228F67',
  '#467B7C',
  '#006064',
  '#795548',
];

export const searchParamNames = {
  collectionCode: 'collectionCode',
} as const;

export const INSIGHTS_DEFAULT_COLOR_MODE = 'light';

export const AI_MODERATED_PARTICIPANT_IDENTITY = {
  USER: 'askable_user',
  AGENT: 'askable_agent',
};
