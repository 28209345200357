import deepmerge from 'deepmerge';
import superjson from 'superjson';

type SharesShape = {
  [share_code: string]: {
    at: string;
    st: string;
  };
};

type StoredValues = {
  viewer_jwt: string;
  viewer_team_id: string;
  observer_identifier: string;
  selected_video_device: string;
  share_token: string;
};

type Setters =
  | {
      key: 'share_token';
      value: string;
    }
  | {
      key: 'viewer_jwt';
      value: string;
    }
  | {
      key: 'observer_identifier';
      value: string;
    }
  | {
      key: 'viewer_team_id';
      value: string;
    }
  | {
      key: 'selected_video_device';
      value: string;
    }
  | {
      key: 'shares';
      value: SharesShape;
    };

type KeysOfUnion<T> = T extends T ? keyof T : never;

export class TypedStorage {
  private static KEY = 'viewer';

  private static persist(newValues: StoredValues) {
    localStorage.setItem(TypedStorage.KEY, superjson.stringify(newValues));
  }

  private static get values(): StoredValues {
    const storedValues = localStorage.getItem(TypedStorage.KEY);

    if (!storedValues) {
      return {} as StoredValues;
    }

    return superjson.parse(storedValues);
  }

  static get<T extends KeysOfUnion<StoredValues>>(k: T) {
    return this?.values?.[k] as StoredValues[T];
  }

  static get getAll() {
    return this.values;
  }

  static set(k: Setters) {
    const updated = deepmerge<StoredValues>(this.values, { [k.key]: k.value });

    this.persist(updated);
  }

  static clear() {
    localStorage.removeItem(TypedStorage.KEY);
  }

  static remove(v: keyof StoredValues) {
    const nv = {
      ...this.values,
    };

    delete nv[v];

    this.persist(nv);
  }
}
