import i18n from 'i18next';
import { crush, get, set } from 'radash';
import { initReactI18next } from 'react-i18next';
import { z } from 'zod';
import { zodI18nMap } from 'zod-i18n-map';
import zodTranslationEn from 'zod-i18n-map/locales/en/zod.json';
import zodTranslationFr from 'zod-i18n-map/locales/fr/zod.json';
import zodTranslationJp from 'zod-i18n-map/locales/ja/zod.json';

import en from './translations/en.json';
import fr from './translations/fr.json';
import jp from './translations/jp.json';

// Zod translation
const updatedZodTranslations = (() => {
  const fields = crush(en.zod);
  let zodEn = zodTranslationEn;
  let zodFr = zodTranslationFr;
  let zodJp = zodTranslationJp;
  Object.keys(fields).forEach(key => {
    const enValue = get(en, `zod.${key}`);
    const frValue = get(fr, `zod.${key}`);
    const jpValue = get(jp, `zod.${key}`);

    // set doesn't seem adding new field to original object but the return has the new field
    zodEn = set(zodTranslationEn, key, enValue);
    zodFr = set(zodTranslationFr, key, frValue);
    zodJp = set(zodTranslationJp, key, jpValue);
  });

  return {
    en: zodEn,
    fr: zodFr,
    jp: zodJp,
  };
})();

// For type safety and intellisense
const translationDefault = { translation: en, zod: updatedZodTranslations.en } as const;

type SupportedLanguage = 'en' | 'fr' | 'jp';
type Translation = typeof translationDefault;

// Fetch language from local storage, if available
const savedLanguage = (() => {
  if (typeof window !== 'undefined') {
    return window.localStorage.getItem('lang') as SupportedLanguage;
  }

  return 'en';
})();
const defaultLanguage = 'en';

// Ensure that other translations include all the keys from the default translation
const translationFR: Translation = { translation: fr, zod: updatedZodTranslations.fr };
const translationJP: Translation = { translation: jp, zod: updatedZodTranslations.jp };
// Labels for the language switcher
const supportedLanguages: { code: SupportedLanguage; label: string }[] = [
  { code: 'en', label: 'English' },
  { code: 'fr', label: 'Français' },
  { code: 'jp', label: '日本語' },
];

i18n.use(initReactI18next).init({
  resources: {
    en: translationDefault,
    fr: translationFR,
    jp: translationJP,
  },
  lng: savedLanguage && supportedLanguages.find(l => l.code === savedLanguage) ? savedLanguage : defaultLanguage,
  fallbackLng: defaultLanguage,
  interpolation: {
    escapeValue: false,
  },
});

z.setErrorMap(zodI18nMap);

export { z };

export default i18n;
export { supportedLanguages, translationDefault };
export type { SupportedLanguage };
