const safeWindow = typeof window === 'undefined' ? ({} as Partial<Window>) : window;

const environment = {
  isProductionDeployment:
    process.env.VERCEL_ENV === 'production' || process.env.NEXT_PUBLIC_VERCEL_ENV === 'production',
  isProductionBuild: process.env.NODE_ENV === 'production',
  isDemo:
    process.env.VERCEL_ENV !== 'production' &&
    (safeWindow?.location?.hostname === 'sessions-demo.askable.com' ||
      safeWindow?.localStorage?.getItem('isDemo') === 'true'),
  featureFlagsApiKey: process.env.NEXT_PUBLIC_SPLIT_API_KEY as string,
};

if (environment.isDemo && process.env.NEXT_PUBLIC_SPLIT_DEMO_API_KEY) {
  environment.featureFlagsApiKey = process.env.NEXT_PUBLIC_SPLIT_DEMO_API_KEY;
}

export { environment };
