import { TypedStorage } from '@/utils/TypedStorage';

export const legacyGetToken = () => {
  if (typeof window === 'undefined') {
    return undefined;
  }

  return TypedStorage.get('viewer_jwt') ?? undefined;
};

export const AUTH_CONFIG: { getToken: () => string | undefined | null } = {
  getToken: () => {
    return legacyGetToken();
  },
};
