import { StatusCodes } from 'http-status-codes';
import { useSearchParams } from 'next/navigation';
import { useEffect, useMemo, useState } from 'react';
import { useQuery } from 'urql';

import { AUTH_CONFIG } from '@/src/network/auth';
import { formatFullName } from '@/utils/string-utils';

import { QUERY_PARAM } from '../../constants/constants';
import { UserType } from '../../generated/graphql';
import { TypedStorage } from '../../utils/TypedStorage';
import { createContext } from '../createContext';

import { ViewerQuery } from './data/Viewer.query';

import type { ResultOf } from '@/src/graphql';
import type { ReactElement, ReactNode } from 'react';

export type ViewerContextStatus = 'authenticated' | 'unauthenticated' | 'unknown' | 'resolving';

type ViewerContextState = {
  status: ViewerContextStatus;
  type: UserType[];
  // Team name tied to booking for current askable live
  details?: ResultOf<typeof ViewerQuery>['viewer'];
  fetchingDetails: boolean;
  updateViewerStatus: () => void;
  setViewerTeam: (teamId: string) => void;

  intercomSettings: Intercom_.IntercomSettings | undefined;
};

type ViewerContextContext = ViewerContextState;

const [ViewerContextProviderComp, useViewerContext] = createContext<ViewerContextContext>({
  name: 'ViewerContext',
});

const ViewerContextProvider = ({ children }: { children: ReactNode }): ReactElement => {
  const searchParams = useSearchParams();

  function initViewerTeamState(): null | string {
    if (typeof window === 'undefined') {
      return null;
    }

    return searchParams?.get(QUERY_PARAM.TEAM_ID) ?? TypedStorage.get('viewer_team_id') ?? null;
  }

  const [status, setStatus] = useState<ViewerContextState['status']>('unknown');
  const [viewerTeam, setViewerTeamState] = useState<string | null>(initViewerTeamState());

  useEffect(() => {
    const teamId = searchParams?.get(QUERY_PARAM.TEAM_ID);

    if (teamId) {
      setViewerTeam(teamId);
    }
  }, [searchParams]);

  const [{ data: viewerDetailsData, fetching, error }, getViewer] = useQuery({
    query: ViewerQuery,
    pause: true,
    variables: { teamId: viewerTeam ?? null },
  });

  useEffect(() => {
    if (!fetching && !error && viewerDetailsData) {
      setStatus('authenticated');
    }
  }, [viewerDetailsData, error, fetching]);

  const updateViewerStatus = () => {
    const token = AUTH_CONFIG.getToken();

    if (!token || typeof token !== 'string') {
      setStatus('unauthenticated');
      return;
    }

    setStatus('resolving');
    getViewer();
  };

  useEffect(() => {
    updateViewerStatus();
  }, []);

  useEffect(() => {
    if (!viewerDetailsData?.viewer?._id) {
      return;
    }

    if (!viewerTeam && viewerDetailsData.viewer._default_team) {
      setViewerTeam(viewerDetailsData.viewer._default_team);
    }
  }, [viewerTeam, viewerDetailsData]);

  const type = (() => {
    const types: UserType[] = [];

    if (viewerDetailsData?.viewer?.type?.researcher) {
      types.push(UserType.Researcher);
    }

    if (viewerDetailsData?.viewer?.type?.client) {
      types.push(UserType.Client);
    }

    if (viewerDetailsData?.viewer?.type?.participant) {
      types.push(UserType.Participant);
    }

    return types;
  })();

  const intercomSettings: Intercom_.IntercomSettings | undefined = useMemo(() => {
    if (status === 'unknown' || status === 'resolving') {
      return undefined;
    }
    if (viewerDetailsData?.viewer) {
      return {
        app_id: process.env.NEXT_PUBLIC_INTERCOM_APP_ID,
        email: viewerDetailsData.viewer.email || undefined,
        name: formatFullName(
          viewerDetailsData.viewer.meta?.identity?.firstname ?? '',
          viewerDetailsData?.viewer.meta?.identity?.lastname ?? '',
        ),
        Company: viewerDetailsData?.viewer.ConnectedTeam?.name,
        onPage: window.location.pathname,
        user_id: viewerDetailsData?.viewer._id,
        phone: viewerDetailsData.viewer.contact?.phone?.mobile ?? undefined,
        customAttributes: {
          // Not sure if intercom can take an array user_type, so take the first. Order of types (if present) is researcher, client, participant
          user_type: type ? type[0] : undefined,
          name: 'user type',
        },
      };
    }
    return {
      app_id: process.env.NEXT_PUBLIC_INTERCOM_APP_ID,
    };
  }, [status, viewerDetailsData, type]);

  const setViewerTeam = (teamId: string) => {
    TypedStorage.set({ key: 'viewer_team_id', value: teamId });
    setViewerTeamState(teamId);
  };

  // If URL team id doesn't return a team (eg, not found, access denied), clear the team id from local storage
  useEffect(() => {
    if (viewerTeam && viewerDetailsData?.viewer?._id && !viewerDetailsData?.viewer?.ConnectedTeam?._id) {
      setViewerTeamState(null);

      TypedStorage.remove('viewer_team_id');
    }
  }, [viewerTeam, viewerDetailsData?.viewer]);

  useEffect(() => {
    if (
      error?.graphQLErrors.some(e => e.extensions.code === StatusCodes.UNAUTHORIZED) ||
      error?.message.includes('jwt expired') ||
      error?.message.includes('invalid token')
    ) {
      TypedStorage.remove('viewer_jwt');
      setStatus('unauthenticated');
    }
  }, [error]);

  return (
    <ViewerContextProviderComp
      value={{
        type,
        details: viewerDetailsData?.viewer,
        fetchingDetails: fetching,
        status: fetching ? 'unknown' : status,
        updateViewerStatus,
        setViewerTeam,
        intercomSettings,
      }}
    >
      {children}
    </ViewerContextProviderComp>
  );
};

export { ViewerContextProvider, useViewerContext };
