import { graphql } from '@/src/graphql';

export const ViewerQuery = graphql(`
  query ViewerDetails($teamId: ID) {
    viewer {
      _id
      _default_team
      displayName
      ConnectedTeam(teamId: $teamId) {
        _id
        name
      }
      meta {
        identity {
          firstname
          lastname
        }
      }
      contact {
        phone {
          mobile
        }
      }
      kinde_user_id
      Teams {
        _id
        name
        kinde_org_id
        kinde_org_id
      }
      email
      type {
        participant
        client
        researcher
      }
    }
  }
`);
