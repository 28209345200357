import { AskableVideoUserType } from '../generated/graphql';

type TwilioRoomIdentifierType = {
  _id: string;
  name: string;
  userType: AskableVideoUserType;
};

export function decodeTwilioRoomIdentifier(identity = ''): TwilioRoomIdentifierType {
  const [name, _id, userType] = window.atob(identity).split(',') as [string, string, 1 | 2 | 3];

  const userTypeMap: { [key in typeof userType]: AskableVideoUserType } = {
    1: AskableVideoUserType.Facilitator,
    2: AskableVideoUserType.Participant,
    3: AskableVideoUserType.Observer,
  };
  return {
    name,
    _id,
    userType: userTypeMap[userType],
  };
}

export function formatFullName(firstName = '', lastName = '') {
  return `${firstName} ${lastName}`.replace(/^\s+|\s+$/g, '');
}

export function cleanGenericError(message?: any) {
  if (typeof message === 'string') {
    return message.replace(/^\[[^\]]*\]\s*/, '');
  }
  return message;
}
