import { useRef, useMemo } from 'react';

import { createContext } from '../createContext';

import type { ReactNode, ReactElement } from 'react';

const isWindow = () => typeof window !== 'undefined';

class Intercom {
  static boot(settings: Intercom_.IntercomSettings) {
    if (!isWindow()) {
      return;
    }

    window.Intercom('boot', settings);
  }

  static update(settings: Intercom_.IntercomSettings) {
    if (!isWindow()) {
      return;
    }
    window.Intercom('update', settings);
  }

  static show() {
    if (!isWindow()) {
      return;
    }
    window.Intercom('show');
  }

  static showMessages() {
    if (!isWindow()) {
      return;
    }
    window.Intercom('showMessages');
  }

  static hide() {
    if (!isWindow()) {
      window.Intercom('hide');
    }
  }
}

type IntercomContextState = {
  boot: (settings: Intercom_.IntercomSettings) => void;

  update: (settings: Intercom_.IntercomSettings) => void;
  show: () => void;
  showMessages: () => void;
  hide: () => void;
};

const [IntercomProviderComp, useIntercom] = createContext<IntercomContextState>({
  name: 'IntercomContext',
});

const IntercomProvider = ({ children }: { children: ReactNode }): ReactElement => {
  const isBooted = useRef(false);

  const stackedIntercomUpdates = useRef<Intercom_.IntercomSettings[]>([]);

  const value = useMemo(() => {
    const boot = (settings: Intercom_.IntercomSettings) => {
      Intercom.boot({
        hide_default_launcher: true,
        api_base: 'https://api-iam.intercom.io',
        ...settings,
      });
      isBooted.current = true;
      stackedIntercomUpdates.current.forEach(setting => {
        Intercom.update(setting);
      });
      stackedIntercomUpdates.current = [];
    };

    const update = (settings: Intercom_.IntercomSettings) => {
      // If it hasn't booted yet, stack up the request
      if (!isBooted.current) {
        stackedIntercomUpdates.current.push(settings);
        return;
      }
      Intercom.update(settings);
    };

    const show = () => {
      Intercom.show();
    };

    const showMessages = () => {
      Intercom.showMessages();
    };

    const hide = () => {
      Intercom.hide();
    };
    return {
      boot,
      update,
      show,
      showMessages,
      hide,
    };
  }, []);

  return <IntercomProviderComp value={value}>{children}</IntercomProviderComp>;
};

export { IntercomProvider, useIntercom };
