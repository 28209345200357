import React from 'react';

export interface CreateContextOptions {
  /**
   * The display name of the context
   * Need for React DevTools
  // https://reactjs.org/docs/context.html#contextdisplayname
   */
  name: string;
}

type CreateContextReturn<T> = [React.Provider<T>, () => T];

export function createContext<ContextType>({ name }: CreateContextOptions): CreateContextReturn<ContextType> {
  const Context = React.createContext<ContextType | undefined>(undefined);

  Context.displayName = name;

  function useContext() {
    const context = React.useContext(Context);
    // Emits error if Provider is not wrapped properly
    if (!context && process.env.NODE_ENV !== 'test') {
      const error = new Error(
        `useContext: ${name} is undefined. Seems you forgot to wrap component within the Provider `,
      );
      error.name = 'ContextError';
      throw error;
    }
    return context;
  }

  return [Context.Provider, useContext] as CreateContextReturn<ContextType>;
}
